import BGHero from "components/BGHero";
import React from "react";

import { Trans, useTranslation } from "react-i18next";

import "../css/AboutUs.css";

import competenzaPng from "../assets/Competenza.png";
import inclusivitaPng from "../assets/Inclusivita.png";
import innovazionePng from "../assets/Innovazione.png";

import giovanniG from "../assets/Giovanni_Finale.png";
import micheleB from "../assets/Michele_Finale.png";
import salvatoreG from "../assets/Salvatore_Finale.png";
import paulMagro from "../assets/Paul-Magro-6.png";
import stefanoFlorino from "../assets/Florino-2.png";
import jCamiller from "../assets/camilleri.jpg";
import aPolzot from "../assets/polzot.jpg";
import aSchmidt from "../assets/aSchmidt.jpg";
import gMoraglia from "../assets/moraglia.jpg";
import gCortesi from "../assets/cortesi.jpg";
import aSilvestri from "../assets/silvestri.jpg";

import groupOrg from "../assets/Organisation-Graph-Rigsave.png";
import { Link } from "react-router-dom";

function AboutUs() {
  const { t } = useTranslation();

  const textHome = t("main.aboutUs.bgHero");

  return (
    <div className="about-us">
      <BGHero params={{ text: textHome, isTypewriterOn: false }} />
      <div className="wrapper-struttura-gruppo pt-5 pb-5 mb-4">
        <div className="container px-5">
          <Trans>{t("main.aboutUs.descParag")}</Trans>
        </div>
      </div>
      <div className="container container-vision mb-5 pb-5">
        <div className="d-lg-flex mt-5 pt-5">
          <div className="col-lg-4 img-vision-wrapper text-end">
            <img src={inclusivitaPng} alt="Advisory" />
          </div>
          <div className="col-lg-5 ps-5 text-start">
            <h3>{t("main.home.gestioneDisc")}</h3>
            <p className="mb-4">{t("main.home.gestioneDiscDesc")}</p>
          </div>
          <div className="col-lg-3"></div>
        </div>
        <div className="d-lg-flex vision-odd-container mt-5 pt-5">
          <div className="col-lg-3"></div>
          <div className="col-lg-5 pe-5 text-end">
            <h3>{t("main.home.fundManagement")}</h3>
            <p className="mb-4">{t("main.home.fundManagementDesc")}</p>
            <div className="d-flex justify-content-end"></div>
          </div>
          <div className="col-lg-4 img-vision-wrapper text-start">
            <img src={innovazionePng} alt="Advisory" />
          </div>
        </div>
        <div className="d-lg-flex mt-5 pt-5">
          <div className="col-lg-4 img-vision-wrapper text-end">
            <img src={competenzaPng} alt="Advisory" />
          </div>
          <div className="col-lg-5 ps-5 text-start">
            <h3>{t("main.home.structureProd")}</h3>
            <p className="mb-4">{t("main.home.structureProdDesc")}</p>
          </div>
          <div className="col-lg-3"></div>
        </div>
      </div>
      <div className="wrapper-struttura-gruppo">
        <div className="container d-flex justify-content-center align-items-center pt-5 pb-5">
          <div className="rigsave-struttura-gruppo">
            <img
              className="group-organization"
              src={groupOrg}
              alt="Group organization"
            />
          </div>
        </div>
        <div className="container d-lg-flex cont-info justify-content-center align-items-center pt-5 pb-5">
          <div className="col-lg-4 px-4">
            <p className="rig-title">
              <strong>RIGSAVE HOLDING LTD</strong>
            </p>
            <span>{t("main.aboutUs.financialHolding")}</span>
            <p className="pt-1">
              {t("main.aboutUs.financialHoldingDescription")}
            </p>
          </div>
          <div className="col-lg-4 px-4">
            <p className="rig-title">
              <strong>RIGSAVE CAPITAL LTD</strong>
            </p>
            <span>{t("main.aboutUs.assetManagement")}</span>
            <p className="pt-1">
              {t("main.aboutUs.assetManagementDescription")}
            </p>
          </div>
          <div className="col-lg-4 px-4">
            <p className="rig-title">
              <strong>RIGSAVE TECH</strong>
            </p>
            <span>{t("main.aboutUs.fintech")}</span>
            <p className="pt-1">{t("main.aboutUs.fintechDescription")}</p>
          </div>
        </div>
      </div>

      <div className="container container-team-restricted justify-content-center text-center mt-5 pt-5 mb-5 pb-3">
        <div className="row pb-5 mb-5">
          <div className="col-lg-4 px-5">
            <img src={giovanniG} alt="Giovanni Gervasi" />
            <div className="pt-3 pb-2 name">Giovanni Gervasi</div>
            <p>
              Co-founder, Investment Committee Member, Portfolio Manager and
              Investment Advisor
            </p>
            <Link
              to={
                "https://www.linkedin.com/in/giovanni-gervasi-cefa-ba354a109/"
              }
            >
              Linkedin
            </Link>
          </div>
          <div className="col-lg-4 px-5">
            <img src={micheleB} alt="Michele Basilicata" />
            <div className="pt-3 pb-2 name">Michele Basilicata</div>
            <p>
              Co-founder, Director, Country Head Italy, Relationship Managing
              Director
            </p>
            <Link
              to={"https://www.linkedin.com/in/michele-basilicata-a3b5658b/"}
            >
              Linkedin
            </Link>
          </div>
          <div className="col-lg-4 px-5">
            <img src={salvatoreG} alt="Salvatore Gervasi" />
            <div className="pt-3 pb-2 name">Salvatore Gervasi</div>
            <p>Co-founder, Director and Investment Management Vice President</p>
            <Link
              to={"https://www.linkedin.com/in/salvatore-gervasi-443404112/"}
            >
              Linkedin
            </Link>
          </div>
        </div>
        <div className="row mt-5 pb-5 mb-5">
          <div className="col-lg-4 px-5">
            {/* <img src={jeanPaulGauci} alt="Jean Paul Gauci" /> */}
            <div className="pt-3 pb-2 name">Jean Paul Gauci</div>
            <p>Risk Manager and Valuation Officier</p>
            <Link to={"https://mt.linkedin.com/in/jeanpaul-gauci"}>
              Linkedin
            </Link>
          </div>
          <div className="col-lg-4 px-5">
            <img src={paulMagro} alt="Paul Magro" />
            <div className="pt-3 pb-2 name">Paul Magro</div>
            <p>Money Laundering Reporting Officier</p>
            <Link to={"https://www.linkedin.com/in/paulzmagro/"}>Linkedin</Link>
          </div>
          <div className="col-lg-4 px-5">
            <img src={jCamiller} alt="Joseph Camilleri" />
            <div className="pt-3 pb-2 name">Joseph Camilleri</div>
            <p>Indipendent Director</p>
            <Link to={"https://www.linkedin.com/in/joseph-camilleri-75081223/"}>
              Linkedin
            </Link>
          </div>
        </div>
        <div className="row mt-5 pb-5 mb-5">
          <div className="col-lg-4 px-5">
            {/* <img src={rBuhagiar} alt="Roberta M. Buhagiar" /> */}
            <div className="pt-3 pb-2 name">Roberta M. Buhagiar</div>
            <p>Compliance officer</p>
            <Link to={"https://www.linkedin.com/in/roberta-buhagiar-86700765/"}>
              Linkedin
            </Link>
          </div>
          <div className="col-lg-4 px-5">
            {/* <img src={jeanPaulGauci} alt="Jean Paul Gauci" /> */}
            <div className="pt-3 pb-2 name">Glen Mifsud</div>
            <p>Portfolio manager and Investment committee member</p>
            <Link
              to={
                "https://www.linkedin.com/in/glen-mifsud-cfa-750820a1/?originalSubdomain=mt"
              }
            >
              Linkedin
            </Link>
          </div>
          <div className="col-lg-4 px-5">
            <img src={aPolzot} alt="Andrea Polzot" />
            <div className="pt-3 pb-2 name">Andrea Polzot</div>
            <p>Head of Finance and Operation</p>
            <Link to={"https://www.linkedin.com/in/andreapolzot/"}>
              Linkedin
            </Link>
          </div>
        </div>
        <div className="row mt-5 mb-5">
          <div className="col-lg-4 px-5">
            <img src={aSchmidt} alt="Amelie Sophie Schmidt" />
            <div className="pt-3 pb-2 name">Amelie Sophie Schmidt</div>
            <p>Compliance Associate</p>
            <Link
              to={
                "https://www.linkedin.com/in/amelie-sophie-schmidt-099520221/"
              }
            >
              Linkedin
            </Link>
          </div>
          <div className="col-lg-4 px-5">
            <img src={gMoraglia} alt="Giulia Moraglia" />
            <div className="pt-3 pb-2 name">Giulia Moraglia</div>
            <p>Relationship Manager</p>
            <Link to={"https://www.linkedin.com/in/giulia-moraglia-7065ba176/"}>
              Linkedin
            </Link>
          </div>
          <div className="col-lg-4 px-5">
            <img src={gCortesi} alt="Giovanni Vittorio Cortesi" />
            <div className="pt-3 pb-2 name">Giovanni Vittorio Cortesi</div>
            <p>Relationship Manager</p>
            <Link
              to={
                "https://www.linkedin.com/in/giovanni-vittorio-cortesi-a2441914/"
              }
            >
              Linkedin
            </Link>
          </div>
        </div>
        <div className="row mt-5 mb-5">
          <div className="col-lg-4 px-5">
            <img src={aSilvestri} alt="Andrea Silvestri" />
            <div className="pt-3 pb-2 name">Andrea Silvestri</div>
            <p>Relationship Associate</p>
            <Link
              to={"https://www.linkedin.com/in/andrea-silvestri-aa983a182/"}
            >
              Linkedin
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
