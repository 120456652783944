import React from "react";
import { Link } from "react-router-dom";

import {
  faFacebook,
  faInstagram,
  faLinkedin,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot, faPhone } from "@fortawesome/free-solid-svg-icons";

import "../css/Footer.css";

import companyLogoBianco from "../assets/logonuovo.png";

function Footer() {
  return (
    <footer>
      <div className="container">
        <div className="row row-footer-legal row-info pt-4">
          <div className="col-10">
            {/* <div className="d-flex pb-1">
              <Link to={"/"} className="list-inline text-center">
                <img
                  src={companyLogoBianco}
                  className="company-logo-footer pb-1"
                  alt="Rigsave capital LTD logo"
                />
              </Link>
            </div>
            <p className="text-rigsave-legal">Rigsave capital LTD</p>
            <p>
              <FontAwesomeIcon icon={faLocationDot} /> C.so Giuseppe Zanardelli
              38, Brescia (BS)
            </p>
            <p>
              <FontAwesomeIcon icon={faPhone} /> 030 3756520
            </p> */}

            <p>
              Rigsave Capital LTD è una società regolamentata da Malta Financial
              Service Autorithy | Autorizzazione C92570 rilasciata in data 11
              Dicembre 2019 Old Bakery Street 171, Valletta, Malta | P.IVA
              MT26557836 Capitale Sociale Autorizzato 700.000EUR di cui versati
              460.000EUR | LEI CODE 9845008G1EB2E4BC1E22
            </p>
            <p>Sede legale: Old Bakery Street 171, Valletta, Malta</p>
            <p>Sedi operative:</p>
            <p>37 Dolphin Court-M, Embassy Way, Ta' Xbiex, Malta</p>
            <p>C.so Giuseppe Zanardelli 38, 25121, Brescia, Italia</p>
          </div>
          <div className="col-2">
            <div className="d-flex justify-content-center">
              <p className="text-rigsave-legal">SOCIAL</p>
            </div>
            <div className="d-flex justify-content-center">
              <Link to={"https://www.facebook.com/rigsave"}>
                <p className="px-1">
                  <FontAwesomeIcon icon={faFacebook} />
                </p>
              </Link>
              <Link to={"https://www.instagram.com/rigsave/"}>
                <p className="px-1">
                  <FontAwesomeIcon icon={faLinkedin} />
                </p>
              </Link>
              <Link
                to={"https://www.linkedin.com/company/rigsave-capital-sarl/"}
              >
                <p className="px-1">
                  <FontAwesomeIcon icon={faInstagram} />
                </p>
              </Link>
              <Link
                to={"https://www.youtube.com/channel/UCORzaiah8yhq5Q_iYd0uXVA"}
              >
                <p className="px-1">
                  <FontAwesomeIcon icon={faYoutube} />
                </p>
              </Link>
            </div>
          </div>
        </div>
        <div className="row row-footer-legal">
          <div className="justify-content-center d-flex">
            <Link
              to={"https://www.iubenda.com/privacy-policy/35880472"}
              className="nav-link px-5"
            >
              Privacy policy
            </Link>
            <Link
              to={
                "https://www.iubenda.com/privacy-policy/35880472/cookie-policy"
              }
              className="nav-link px-5"
            >
              Cookie Policy
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
