import BGHero from "components/BGHero";
import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { getUrl } from "aws-amplify/storage";

import innovazionePng from "../assets/Innovazione.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";

import "../css/FundManagement.css";
import { Link } from "react-router-dom";

function FundManagement() {
  const { t } = useTranslation();

  const textHero = t("main.fundManagement.bgHero");
  const [prospetto, setProspetto] = useState("");
  const [websiteDisclosure, setWebsiteDisclosure] = useState("");
  const [sottoscrizioneSoc, setSottoscrizioneSoc] = useState("");
  const [sottoscrizionePers, setSottoscrizionePers] = useState("");
  const [PRIIPSKID_R_IT, setPRIIPSKID_R_IT] = useState("");
  const [PRIIPSKID_I_IT, setPRIIPSKID_I_IT] = useState("");
  const [PRIIPSKID_R_EN, setPRIIPSKID_R_EN] = useState("");
  const [PRIIPSKID_I_EN, setPRIIPSKID_I_EN] = useState("");
  const [factsheetAprile2022, setFactsheetAprile2022] = useState("");
  const [factsheetMaggio2022, setFactsheetMaggio2022] = useState("");
  const [factsheetGiugno2022, setFactsheetGiugno2022] = useState("");
  const [factsheetLuglio2022_I, setFactsheetLuglio2022_I] = useState("");
  const [factsheetLuglio2022_R, setFactsheetLuglio2022_R] = useState("");
  const [factsheetAgosto_2022_I, setFactsheetAgosto_2022_I] = useState("");
  const [factsheetAgosto_2022_R, setFactsheetAgosto_2022_R] = useState("");
  const [factsheetSettembre_2022_I, setFactsheetSettembre_2022_I] =
    useState("");
  const [factsheetSettembre_2022_R, setFactsheetSettembre_2022_R] =
    useState("");
  const [factsheetOttobre_2022_I, setFactsheetOttobre_I] = useState("");
  const [factsheetOttobre_2022_R, setFactsheetOttobre_R] = useState("");
  const [factsheetNovembre_2022_I, setFactsheetNovembre_2022_I] = useState("");
  const [factsheetNovembre_2022_R, setFactsheetNovembre_2022_R] = useState("");
  const [factsheetDicembre_2022_I, setFactsheetDicembre_2022_I] = useState("");
  const [factsheetDicembre_2022_R, setFactsheetDicembre_2022_R] = useState("");
  const [factsheetGennaio_2023_I, setFactsheetGennaio_2023_I] = useState("");
  const [factsheetGennaio_2023_R, setFactsheetGennaio_2023_R] = useState("");
  const [factsheetFebbraio_2023_I, setFactsheetFebbraio_2023_I] = useState("");
  const [factsheetFebbraio_2023_R, setFactsheetFebbraio_2023_R] = useState("");
  const [factsheetMarzo_2023_I, setFactsheetMarzo_2023_I] = useState("");
  const [factsheetMarzo_2023_R, setFactsheetMarzo_2023_R] = useState("");
  const [factsheetAprile_2023_I, setFactsheetAprile_2023_I] = useState("");
  const [factsheetAprile_2023_R, setFactsheetAprile_2023_R] = useState("");
  const [factsheetMaggio_2023_I, setFactsheetMaggio_2023_I] = useState("");
  const [factsheetMaggio_2023_R, setFactsheetMaggio_2023_R] = useState("");
  const [factsheetGiugno_2023_I, setFactsheetGiugno_2023_I] = useState("");
  const [factsheetGiugno_2023_R, setFactsheetGiugno_2023_R] = useState("");
  const [factsheetLuglio_2023_I, setFactsheetLuglio_2023_I] = useState("");
  const [factsheetLuglio_2023_R, setFactsheetLuglio_2023_R] = useState("");
  const [factsheetAgosto_2023_I, setFactsheetAgosto_2023_I] = useState("");
  const [factsheetAgosto_2023_R, setFactsheetAgosto_2023_R] = useState("");
  const [factsheetSettembre_2023_I, setFactsheetSettembre_2023_I] =
    useState("");
  const [factsheetSettembre_2023_R, setFactsheetSettembre_2023_R] =
    useState("");
  const [factsheetOttobre_2023_I, setFactsheetOttobre_2023_I] = useState("");
  const [factsheetOttobre_2023_R, setFactsheetOttobre_2023_R] = useState("");
  const [factsheetNovembre_2023_I, setFactsheetNovembre_2023_I] = useState("");
  const [factsheetNovembre_2023_R, setFactsheetNovembre_2023_R] = useState("");
  const [factsheetDicembre_2023_I, setFactsheetDicembre_2023_I] = useState("");
  const [factsheetDicembre_2023_R, setFactsheetDicembre_2023_R] = useState("");
  const [factsheetGennaio_2024_I, setFactsheetGennaio_2024_I] = useState("");
  const [factsheetGennaio_2024_R, setFactsheetGennaio_2024_R] = useState("");
  const [factsheetFebbraio_2024_I, setFactsheetFebbraio_2024_I] = useState("");
  const [factsheetFebbraio_2024_R, setFactsheetFebbraio_2024_R] = useState("");
  const [annualReport_march_2022, setAnnualReport_march_2022] = useState("");
  const [annualReport_march_2023, setAnnualReport_march_2023] = useState("");
  const [semiAnnualReport_sett_2022, setSemiAnnualReport_sett_2022] =
    useState("");
  const [semiAnnualReport_sett_2023, setSemiAnnualReport_sett_2023] =
    useState("");
  const [factsheetMarch_2024_I, setFactsheetMarch_2024_I] = useState("");
  const [factsheetMarch_2024_R, setFactsheetMarch_2024_R] = useState("");
  const [FSAnnualMarch2024, setFSAnnualMarch2024] = useState("");

  async function downlaodFiles() {
    setProspetto(
      (
        await getUrl({
          key: "fundManagement/Rigsave-Fund-SICAV_Prospectus-July-2023-visa-stamped.pdf",
        })
      ).url.toString()
    );
    setWebsiteDisclosure(
      (
        await getUrl({
          key: "fundManagement/Website-disclosure-Rigsave-Global-Equity-Value-ARC-26.05.2023_VF_Clean.pdf",
        })
      ).url.toString()
    );
    setSottoscrizioneSoc(
      (
        await getUrl({
          key: "fundManagement/Modulo-di-sottoscrizione-societa.pdf",
        })
      ).url.toString()
    );
    setSottoscrizionePers(
      (
        await getUrl({
          key: "fundManagement/Modulo-di-sottoscrizione-persona-fisica.pdf",
        })
      ).url.toString()
    );
    /*
    setPRIIPSKID_R(
      (
        await getUrl({
          key: "fundManagement/PRIIPS-Manager-V2-KID-V2-2023-07-15-IT-LU2397781605-RIGSAVE-GLOBAL-EQUITY-VALUE-classe-R.pdf",
        })
      ).url.toString()
    );
    setPRIIPSKID_I(
      (
        await getUrl({
          key: "fundManagement/PRIIPS-Manager-V2-KID-V2-2023-07-15-IT-LU2398290747-RIGSAVE-GLOBAL-EQUITY-VALUE-classe-I.pdf",
        })
      ).url.toString()
    );
    */
    setPRIIPSKID_R_EN(
      (
        await getUrl({
          key: "fundManagement/PRIIPS_Manager_V2-KID-V2-2024-03-15-EN-LU2397781605-RIGSAVE_GLOBAL_EQUITY_VALUE-class_R.pdf",
        })
      ).url.toString()
    );
    setPRIIPSKID_I_IT(
      (
        await getUrl({
          key: "fundManagement/PRIIPS_Manager_V2-KID-V2-2024-03-15-IT-LU2398290747-RIGSAVE_GLOBAL_EQUITY_VALUE-classe_I.pdf",
        })
      ).url.toString()
    );
    setPRIIPSKID_R_IT(
      (
        await getUrl({
          key: "fundManagement/PRIIPS_Manager_V2-KID-V2-2024-03-15-IT-LU2397781605-RIGSAVE_GLOBAL_EQUITY-VALUE-classe_R.pdf",
        })
      ).url.toString()
    );
    setPRIIPSKID_I_EN(
      (
        await getUrl({
          key: "fundManagement/PRIIPS_Manager_V2-KID-V2-2024-03-15-EN-LU2398290747-RIGSAVE_GLOBAL_EQUITY_VALUE-class_I.pdf",
        })
      ).url.toString()
    );
    setFactsheetAprile2022(
      (
        await getUrl({
          key: "fundManagement/Rigsave-Factsheet-UCITS-05-2022_16.47.pdf",
        })
      ).url.toString()
    );
    setFactsheetMaggio2022(
      (
        await getUrl({ key: "fundManagement/Termsheet-UCITS-06-2022.pdf" })
      ).url.toString()
    );
    setFactsheetGiugno2022(
      (
        await getUrl({ key: "fundManagement/Termsheet-30-06-2022.pdf" })
      ).url.toString()
    );
    setFactsheetLuglio2022_I(
      (
        await getUrl({
          key: "fundManagement/Termsheet-Luglio-2022-Classe-I.pdf",
        })
      ).url.toString()
    );
    setFactsheetLuglio2022_R(
      (
        await getUrl({
          key: "fundManagement/Termsheet-Luglio-2022-Classe-R.pdf",
        })
      ).url.toString()
    );
    setFactsheetAgosto_2022_I(
      (
        await getUrl({ key: "fundManagement/factsheet31082022_I.pdf" })
      ).url.toString()
    );
    setFactsheetAgosto_2022_R(
      (
        await getUrl({ key: "fundManagement/factsheet31082022_R.pdf" })
      ).url.toString()
    );
    setFactsheetSettembre_2022_I(
      (
        await getUrl({ key: "fundManagement/factsheet31092022_I.pdf" })
      ).url.toString()
    );
    setFactsheetSettembre_2022_R(
      (
        await getUrl({ key: "fundManagement/factsheet31092022_R.pdf" })
      ).url.toString()
    );
    setFactsheetOttobre_I(
      (
        await getUrl({ key: "fundManagement/factsheet31102022_I.pdf" })
      ).url.toString()
    );
    setFactsheetOttobre_R(
      (
        await getUrl({ key: "fundManagement/factsheet31102022_R.pdf" })
      ).url.toString()
    );
    setFactsheetNovembre_2022_I(
      (
        await getUrl({ key: "fundManagement/factsheet31112022_I.pdf" })
      ).url.toString()
    );
    setFactsheetNovembre_2022_R(
      (
        await getUrl({ key: "fundManagement/factsheet31112022_R.pdf" })
      ).url.toString()
    );
    setFactsheetDicembre_2022_I(
      (
        await getUrl({ key: "fundManagement/factsheet31122022_I.pdf" })
      ).url.toString()
    );
    setFactsheetDicembre_2022_R(
      (
        await getUrl({ key: "fundManagement/factsheet31122022_R.pdf" })
      ).url.toString()
    );
    setFactsheetGennaio_2023_I(
      (
        await getUrl({ key: "fundManagement/factsheet31012023_I.pdf" })
      ).url.toString()
    );
    setFactsheetGennaio_2023_R(
      (
        await getUrl({ key: "fundManagement/factsheet31012023_R.pdf" })
      ).url.toString()
    );
    setFactsheetFebbraio_2023_I(
      (
        await getUrl({ key: "fundManagement/factsheet31022023_I.pdf" })
      ).url.toString()
    );
    setFactsheetFebbraio_2023_R(
      (
        await getUrl({ key: "fundManagement/factsheet31022023_R.pdf" })
      ).url.toString()
    );
    setFactsheetMarzo_2023_I(
      (
        await getUrl({ key: "fundManagement/factsheetITA31032023_I.pdf" })
      ).url.toString()
    );
    setFactsheetMarzo_2023_R(
      (
        await getUrl({ key: "fundManagement/factsheetITA31032023_R.pdf" })
      ).url.toString()
    );
    setFactsheetAprile_2023_I(
      (
        await getUrl({ key: "fundManagement/factsheetITA30042023_I.pdf" })
      ).url.toString()
    );
    setFactsheetAprile_2023_R(
      (
        await getUrl({ key: "fundManagement/factsheetITA30042023_R.pdf" })
      ).url.toString()
    );
    setFactsheetMaggio_2023_I(
      (
        await getUrl({ key: "fundManagement/factsheetITA31052023_I.pdf" })
      ).url.toString()
    );
    setFactsheetMaggio_2023_R(
      (
        await getUrl({ key: "fundManagement/factsheetITA31052023_R.pdf" })
      ).url.toString()
    );
    setFactsheetGiugno_2023_I(
      (
        await getUrl({ key: "fundManagement/factsheetITA30062023_I.pdf" })
      ).url.toString()
    );
    setFactsheetGiugno_2023_R(
      (
        await getUrl({ key: "fundManagement/factsheetITA30062023_R.pdf" })
      ).url.toString()
    );
    setFactsheetLuglio_2023_I(
      (
        await getUrl({ key: "fundManagement/factsheetITA20230731_I.pdf" })
      ).url.toString()
    );
    setFactsheetLuglio_2023_R(
      (
        await getUrl({ key: "fundManagement/factsheetITA20230731_R.pdf" })
      ).url.toString()
    );
    setFactsheetAgosto_2023_I(
      (
        await getUrl({ key: "fundManagement/factsheetITA20230831_I.pdf" })
      ).url.toString()
    );
    setFactsheetAgosto_2023_R(
      (
        await getUrl({ key: "fundManagement/factsheetITA20230831_R.pdf" })
      ).url.toString()
    );
    setFactsheetSettembre_2023_I(
      (
        await getUrl({ key: "fundManagement/factsheetITA30092023_I.pdf" })
      ).url.toString()
    );
    setFactsheetSettembre_2023_R(
      (
        await getUrl({ key: "fundManagement/factsheetITA30092023_R.pdf" })
      ).url.toString()
    );
    setFactsheetOttobre_2023_I(
      (
        await getUrl({ key: "fundManagement/factsheetITA20231031_I.pdf" })
      ).url.toString()
    );
    setFactsheetOttobre_2023_R(
      (
        await getUrl({ key: "fundManagement/factsheetITA20231031_R.pdf" })
      ).url.toString()
    );
    setFactsheetNovembre_2023_I(
      (
        await getUrl({ key: "fundManagement/factsheetITA20231130_I.pdf" })
      ).url.toString()
    );
    setFactsheetNovembre_2023_R(
      (
        await getUrl({ key: "fundManagement/factsheetITA20231130_R.pdf" })
      ).url.toString()
    );
    setFactsheetDicembre_2023_I(
      (
        await getUrl({ key: "fundManagement/factsheetITA20231231_I.pdf" })
      ).url.toString()
    );
    setFactsheetDicembre_2023_R(
      (
        await getUrl({ key: "fundManagement/factsheetITA20231231_R.pdf" })
      ).url.toString()
    );
    setFactsheetGennaio_2024_I(
      (
        await getUrl({ key: "fundManagement/factsheetITA20240131_I.pdf" })
      ).url.toString()
    );
    setFactsheetGennaio_2024_R(
      (
        await getUrl({ key: "fundManagement/factsheetITA20240131_R.pdf" })
      ).url.toString()
    );
    setFactsheetFebbraio_2024_I(
      (
        await getUrl({ key: "fundManagement/factsheetITA20240229_I.pdf" })
      ).url.toString()
    );
    setFactsheetFebbraio_2024_R(
      (
        await getUrl({ key: "fundManagement/factsheetITA20240229_R.pdf" })
      ).url.toString()
    );
    setAnnualReport_march_2022(
      (
        await getUrl({
          key: "fundManagement/AR_RIGSAVE-SICAV_31.03.22-signed.pdf",
        })
      ).url.toString()
    );
    setAnnualReport_march_2023(
      (
        await getUrl({
          key: "fundManagement/Rigsave-Fund-SICAV-audited-FS-31032023.pdf",
        })
      ).url.toString()
    );
    setSemiAnnualReport_sett_2022(
      (
        await getUrl({
          key: "fundManagement/1244_20220930_02S-W_ANG_RIGSAVE-FUND-SICAV.pdf",
        })
      ).url.toString()
    );
    setSemiAnnualReport_sett_2023(
      (
        await getUrl({
          key: "fundManagement/unaudited-semi-annual-report-30-september-2023.pdf",
        })
      ).url.toString()
    );
    setFactsheetMarch_2024_I(
      (
        await getUrl({
          key: "fundManagement/factsheetITA20240331_I.pdf",
        })
      ).url.toString()
    );
    setFactsheetMarch_2024_R(
      (
        await getUrl({
          key: "fundManagement/factsheetITA20240331_R.pdf",
        })
      ).url.toString()
    );
    setFSAnnualMarch2024(
      (
        await getUrl({
          key: "fundManagement/FS_RIGSAVE_FUND_SICAV_03_2024.pdf",
        })
      ).url.toString()
    );
  }
  useEffect(() => {
    downlaodFiles();
  });

  return (
    <div id="fundManagement">
      <BGHero params={{ text: textHero, isTypewriterOn: false }} />
      <div className="mt-5 container container-fund d-lg-flex justify-content-center">
        <div className="d-lg-flex">
          <img src={innovazionePng} alt="Innovazione" />
          <div className="mx-4 mb-5">
            <h4 className="text-start">RigSave Global Equity Value - UCITS</h4>
            <p className="text-start">{t("main.fundManagement.legalDoc")}</p>
            <div className="mt-4 mb-5 container-links">
              <a href={prospetto} target="_blank" rel="noreferrer">
                Prospetto <FontAwesomeIcon className="ps-1" icon={faDownload} />
              </a>
              <a href={websiteDisclosure} target="_blank" rel="noreferrer">
                Website Disclosure{" "}
                <FontAwesomeIcon className="ps-1" icon={faDownload} />
              </a>
              <a href={sottoscrizioneSoc} target="_blank" rel="noreferrer">
                Modulo di sottoscrizione - Società{" "}
                <FontAwesomeIcon className="ps-1" icon={faDownload} />
              </a>
              <a href={sottoscrizionePers} target="_blank" rel="noreferrer">
                Modulo di sottoscrizione - Persona fisica{" "}
                <FontAwesomeIcon className="ps-1" icon={faDownload} />
              </a>
              <a href={PRIIPSKID_R_IT} target="_blank" rel="noreferrer">
                PRIIPS KID IT - Classe R{" "}
                <FontAwesomeIcon className="ps-1" icon={faDownload} />
              </a>
              <a href={PRIIPSKID_I_IT} target="_blank" rel="noreferrer">
                PRIIPS KID IT - Classe I{" "}
                <FontAwesomeIcon className="ps-1" icon={faDownload} />
              </a>
              <a href={PRIIPSKID_R_EN} target="_blank" rel="noreferrer">
                PRIIPS KID EN - Classe R{" "}
                <FontAwesomeIcon className="ps-1" icon={faDownload} />
              </a>
              <a href={PRIIPSKID_I_EN} target="_blank" rel="noreferrer">
                PRIIPS KID EN - Classe I{" "}
                <FontAwesomeIcon className="ps-1" icon={faDownload} />
              </a>
              <a href={factsheetAprile2022} target="_blank" rel="noreferrer">
                Factsheet Aprile 2022{" "}
                <FontAwesomeIcon className="ps-1" icon={faDownload} />
              </a>
              <a href={factsheetMaggio2022} target="_blank" rel="noreferrer">
                Factsheet Maggio 2022{" "}
                <FontAwesomeIcon className="ps-1" icon={faDownload} />
              </a>
              <a href={factsheetGiugno2022} target="_blank" rel="noreferrer">
                Factsheet Giugno 2022{" "}
                <FontAwesomeIcon className="ps-1" icon={faDownload} />
              </a>
              <a href={factsheetLuglio2022_I} target="_blank" rel="noreferrer">
                Factsheet Luglio 2022 - Classe I{" "}
                <FontAwesomeIcon className="ps-1" icon={faDownload} />
              </a>
              <a href={factsheetLuglio2022_R} target="_blank" rel="noreferrer">
                Factsheet Luglio 2022 - Classe R{" "}
                <FontAwesomeIcon className="ps-1" icon={faDownload} />
              </a>
              <a href={factsheetAgosto_2022_I} target="_blank" rel="noreferrer">
                Factsheet Agosto 2022 - Classe I{" "}
                <FontAwesomeIcon className="ps-1" icon={faDownload} />
              </a>
              <a href={factsheetAgosto_2022_R} target="_blank" rel="noreferrer">
                Factsheet Agosto 2022 - Classe R{" "}
                <FontAwesomeIcon className="ps-1" icon={faDownload} />
              </a>
              <a
                href={factsheetSettembre_2022_I}
                target="_blank"
                rel="noreferrer"
              >
                Factsheet Settembre 2022 - Classe I{" "}
                <FontAwesomeIcon className="ps-1" icon={faDownload} />
              </a>
              <a
                href={factsheetSettembre_2022_R}
                target="_blank"
                rel="noreferrer"
              >
                Factsheet Settembre 2022 - Classe R{" "}
                <FontAwesomeIcon className="ps-1" icon={faDownload} />
              </a>
              <a
                href={factsheetOttobre_2022_I}
                target="_blank"
                rel="noreferrer"
              >
                Factsheet Ottobre 2022 - Classe I{" "}
                <FontAwesomeIcon className="ps-1" icon={faDownload} />
              </a>
              <a
                href={factsheetOttobre_2022_R}
                target="_blank"
                rel="noreferrer"
              >
                Factsheet Ottobre 2022 - Classe R{" "}
                <FontAwesomeIcon className="ps-1" icon={faDownload} />
              </a>
              <a
                href={factsheetNovembre_2022_I}
                target="_blank"
                rel="noreferrer"
              >
                Factsheet Novembre 2022 - Classe I{" "}
                <FontAwesomeIcon className="ps-1" icon={faDownload} />
              </a>
              <a
                href={factsheetNovembre_2022_R}
                target="_blank"
                rel="noreferrer"
              >
                Factsheet Novembre 2022 - Classe R{" "}
                <FontAwesomeIcon className="ps-1" icon={faDownload} />
              </a>
              <a
                href={factsheetDicembre_2022_I}
                target="_blank"
                rel="noreferrer"
              >
                Factsheet Dicembre 2022 - Classe I{" "}
                <FontAwesomeIcon className="ps-1" icon={faDownload} />
              </a>
              <a
                href={factsheetDicembre_2022_R}
                target="_blank"
                rel="noreferrer"
              >
                Factsheet Dicembre 2022 - Classe R{" "}
                <FontAwesomeIcon className="ps-1" icon={faDownload} />
              </a>
              <a
                href={factsheetGennaio_2023_I}
                target="_blank"
                rel="noreferrer"
              >
                Factsheet Gennaio 2023 - Classe I{" "}
                <FontAwesomeIcon className="ps-1" icon={faDownload} />
              </a>
              <a
                href={factsheetGennaio_2023_R}
                target="_blank"
                rel="noreferrer"
              >
                Factsheet Gennaio 2023 - Classe R{" "}
                <FontAwesomeIcon className="ps-1" icon={faDownload} />
              </a>
              <a
                href={factsheetFebbraio_2023_I}
                target="_blank"
                rel="noreferrer"
              >
                Factsheet Febbraio 2023 - Classe I{" "}
                <FontAwesomeIcon className="ps-1" icon={faDownload} />
              </a>
              <a
                href={factsheetFebbraio_2023_R}
                target="_blank"
                rel="noreferrer"
              >
                Factsheet Gennaio 2023 - Classe R{" "}
                <FontAwesomeIcon className="ps-1" icon={faDownload} />
              </a>
              <a href={factsheetMarzo_2023_I} target="_blank" rel="noreferrer">
                Factsheet Marzo 2023 - Classe I{" "}
                <FontAwesomeIcon className="ps-1" icon={faDownload} />
              </a>
              <a href={factsheetMarzo_2023_R} target="_blank" rel="noreferrer">
                Factsheet Marzo 2023 - Classe R{" "}
                <FontAwesomeIcon className="ps-1" icon={faDownload} />
              </a>
              <a href={factsheetAprile_2023_I} target="_blank" rel="noreferrer">
                Factsheet Aprile 2023 - Classe I{" "}
                <FontAwesomeIcon className="ps-1" icon={faDownload} />
              </a>
              <a href={factsheetAprile_2023_R} target="_blank" rel="noreferrer">
                Factsheet Aprile 2023 - Classe R{" "}
                <FontAwesomeIcon className="ps-1" icon={faDownload} />
              </a>
              <a href={factsheetMaggio_2023_I} target="_blank" rel="noreferrer">
                Factsheet Maggio 2023 - Classe I{" "}
                <FontAwesomeIcon className="ps-1" icon={faDownload} />
              </a>
              <a href={factsheetMaggio_2023_R} target="_blank" rel="noreferrer">
                Factsheet Maggio 2023 - Classe R{" "}
                <FontAwesomeIcon className="ps-1" icon={faDownload} />
              </a>
              <a href={factsheetGiugno_2023_I} target="_blank" rel="noreferrer">
                Factsheet Giugno 2023 - Classe I{" "}
                <FontAwesomeIcon className="ps-1" icon={faDownload} />
              </a>
              <a href={factsheetGiugno_2023_R} target="_blank" rel="noreferrer">
                Factsheet Giugno 2023 - Classe R{" "}
                <FontAwesomeIcon className="ps-1" icon={faDownload} />
              </a>
              <a href={factsheetLuglio_2023_I} target="_blank" rel="noreferrer">
                Factsheet Luglio 2023 - Classe I{" "}
                <FontAwesomeIcon className="ps-1" icon={faDownload} />
              </a>
              <a href={factsheetLuglio_2023_R} target="_blank" rel="noreferrer">
                Factsheet Luglio 2023 - Classe R{" "}
                <FontAwesomeIcon className="ps-1" icon={faDownload} />
              </a>
              <a href={factsheetAgosto_2023_I} target="_blank" rel="noreferrer">
                Factsheet Agosto 2023 - Classe I{" "}
                <FontAwesomeIcon className="ps-1" icon={faDownload} />
              </a>
              <a href={factsheetAgosto_2023_R} target="_blank" rel="noreferrer">
                Factsheet Agosto 2023 - Classe R{" "}
                <FontAwesomeIcon className="ps-1" icon={faDownload} />
              </a>
              <a
                href={factsheetSettembre_2023_I}
                target="_blank"
                rel="noreferrer"
              >
                Factsheet Settembre 2023 - Classe I{" "}
                <FontAwesomeIcon className="ps-1" icon={faDownload} />
              </a>
              <a
                href={factsheetSettembre_2023_R}
                target="_blank"
                rel="noreferrer"
              >
                Factsheet Settembre 2023 - Classe R{" "}
                <FontAwesomeIcon className="ps-1" icon={faDownload} />
              </a>
              <a
                href={factsheetOttobre_2023_I}
                target="_blank"
                rel="noreferrer"
              >
                Factsheet Ottobre 2023 - Classe I{" "}
                <FontAwesomeIcon className="ps-1" icon={faDownload} />
              </a>
              <a
                href={factsheetOttobre_2023_R}
                target="_blank"
                rel="noreferrer"
              >
                Factsheet Ottobre 2023 - Classe R{" "}
                <FontAwesomeIcon className="ps-1" icon={faDownload} />
              </a>
              <a
                href={factsheetNovembre_2023_I}
                target="_blank"
                rel="noreferrer"
              >
                Factsheet Novembre 2023 - Classe I{" "}
                <FontAwesomeIcon className="ps-1" icon={faDownload} />
              </a>
              <a
                href={factsheetNovembre_2023_R}
                target="_blank"
                rel="noreferrer"
              >
                Factsheet Novembre 2023 - Classe R{" "}
                <FontAwesomeIcon className="ps-1" icon={faDownload} />
              </a>
              <a
                href={factsheetDicembre_2023_I}
                target="_blank"
                rel="noreferrer"
              >
                Factsheet Dicembre 2023 - Classe I{" "}
                <FontAwesomeIcon className="ps-1" icon={faDownload} />
              </a>
              <a
                href={factsheetDicembre_2023_R}
                target="_blank"
                rel="noreferrer"
              >
                Factsheet Dicembre 2023 - Classe R{" "}
                <FontAwesomeIcon className="ps-1" icon={faDownload} />
              </a>
              <a
                href={factsheetGennaio_2024_I}
                target="_blank"
                rel="noreferrer"
              >
                Factsheet Gennaio 2024 - Classe I{" "}
                <FontAwesomeIcon className="ps-1" icon={faDownload} />
              </a>
              <a
                href={factsheetGennaio_2024_R}
                target="_blank"
                rel="noreferrer"
              >
                Factsheet Gennaio 2024 - Classe R{" "}
                <FontAwesomeIcon className="ps-1" icon={faDownload} />
              </a>
              <a
                href={factsheetFebbraio_2024_I}
                target="_blank"
                rel="noreferrer"
              >
                Factsheet Febbraio 2024 - Classe I{" "}
                <FontAwesomeIcon className="ps-1" icon={faDownload} />
              </a>
              <a
                href={factsheetFebbraio_2024_R}
                target="_blank"
                rel="noreferrer"
              >
                Factsheet Febbraio 2024 - Classe R{" "}
                <FontAwesomeIcon className="ps-1" icon={faDownload} />
              </a>
              <a
                href={annualReport_march_2022}
                target="_blank"
                rel="noreferrer"
              >
                Annual Report - Marzo 2022{" "}
                <FontAwesomeIcon className="ps-1" icon={faDownload} />
              </a>
              <a
                href={annualReport_march_2023}
                target="_blank"
                rel="noreferrer"
              >
                Annual Report - Marzo 2023{" "}
                <FontAwesomeIcon className="ps-1" icon={faDownload} />
              </a>
              <a
                href={semiAnnualReport_sett_2022}
                target="_blank"
                rel="noreferrer"
              >
                Semi Annual Report - Settembre 2022{" "}
                <FontAwesomeIcon className="ps-1" icon={faDownload} />
              </a>
              <a
                href={semiAnnualReport_sett_2023}
                target="_blank"
                rel="noreferrer"
              >
                Semi Annual Report - Settembre 2023{" "}
                <FontAwesomeIcon className="ps-1" icon={faDownload} />
              </a>
              <a href={factsheetMarch_2024_I} target="_blank" rel="noreferrer">
                Factsheet Marzo 2024 - Classe I{" "}
                <FontAwesomeIcon className="ps-1" icon={faDownload} />
              </a>
              <a href={factsheetMarch_2024_R} target="_blank" rel="noreferrer">
                Factsheet Marzo 2024 - Classe R{" "}
                <FontAwesomeIcon className="ps-1" icon={faDownload} />
              </a>
              <a href={FSAnnualMarch2024} target="_blank" rel="noreferrer">
                Annual report - Marzo 2024{" "}
                <FontAwesomeIcon className="ps-1" icon={faDownload} />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="container pb-5 mb-5 text-center">
        <h4>{t("main.fundManagement.moreInfo")}</h4>
        <p>{t("main.fundManagement.moreInfoCaption")}</p>
        <Link className="link-contact-us mt-3" to={"/contact-us"}>
          {t("main.fundManagement.contactUs")}
        </Link>
      </div>
    </div>
  );
}

export default FundManagement;
